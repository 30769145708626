<template>
  <main class="repertoire-page page">
    <BreadcrumbsComponent v-if="repertoire && repertoire.title" :title="repertoire.title" :links="links" />
    <div class="container-padding">
      <div v-if="repertoire" class="repertoire-page__inner">
        <div class="repertoire-page__header">
          <h2 v-if="repertoire.title" class="repertoire-page__title">{{ repertoire.title }}</h2>
          <span v-if="repertoire.description">{{ repertoire.description }}</span>
        </div>
        <div v-if="repertoire.images" class="repertoire-page__body">
          <span v-if="repertoire.composer">
            <b>Композитор: </b>
            <span>{{ repertoire.composer }}</span>
          </span>
          <div class="repertoire-page__actors">
            <b>Актеры:</b>
            <span v-for="(actor, i) in repertoire.level" :key="i">
              <span>{{ actor.actor }} - </span>
              <span>{{ actor.role }}</span>
            </span>
          </div>
          <div class="repertoire-page__characters">
            <b>Действующие лица и исполнители:</b>
            <span>{{ repertoire.dramatis_personae }}</span>
          </div>
        </div>
        <ul v-if="repertoire.images && repertoire.images.length" class="repertoire-page__images">
          <li v-for="(image, i) in repertoire.images" :key="i">
            <a :href="image.img.path" @click.prevent="showGallery(repertoire.images, i)">
              <ImgComponent :img="image.img" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import ImgComponent from "components/ImgComponent.vue";
import GalleryModal from "components/modals/components/GalleryModal.vue";
import REPERTOIRES_OPEN_PAGE from "gql/pages/RepertoiresOpenPage.graphql";

export default {
  name: "RepertoirePage",
  metaInfo() {
    const meta = this.$store.state.repertoires.repertoires_item;
    return {
      title: meta?.title,
    };
  },
  async asyncData({ res, apollo, store, route }) {
    if (!route.params.id && res) {
      res.status(404);
    }
    await apollo.defaultClient
      .query({
        query: REPERTOIRES_OPEN_PAGE,
        variables: {
          id: parseInt(route.params.id),
        },
      })
      .then(({ data }) => {
        if (data) {
          store.dispatch("repertoires/save", data);
        } else {
          if (res) {
            res.status(404);
          }
        }
      })
      .catch(() => {});
  },
  computed: {
    repertoire() {
      return this.$store.state.repertoires.repertoires_item;
    },
    links() {
      let repertoire = this.$store.state.repertoires.repertoires_item;
      return [
        {
          title: repertoire.category_poster.title,
          to: { name: "repertoires", query: { category: repertoire.category_poster.id } },
        },
      ];
    },
  },
  methods: {
    showGallery(images, index = 0) {
      this.$store.state._modals = [
        {
          component: GalleryModal,
          options: {
            images,
            index,
          },
        },
      ];
    },
  },
  components: {
    BreadcrumbsComponent,
    ImgComponent,
  },
};
</script>

<style lang="stylus">
.repertoire-page {

  &__inner {
    padding 50px 0 0 0
    display grid
    grid-gap 30px
  }

  &__header {
    display grid
    grid-gap 15px
  }

  &__title {
    margin-bottom 0
  }

  &__body {
    display flex
    flex-direction column
    gap 20px
  }

  &__images {
    display flex
    flex-wrap wrap
    gap 15px

    li {
      display inline-flex
      width 100%
      max-width 330px
      +below(700px) {
        max-width 400px
      }

      a {
        display inline-flex
        width 100%

        img {
          max-height 320px
          border-radius 10px
        }
      }
    }
  }

  &__actors {
    display flex
    flex-direction column
    gap 5px
  }

  &__characters {
    display flex
    flex-direction column
    gap 5px
  }
}
</style>
